import Input from './Input'
import Choices from 'choices.js'

class InputSelect extends Input {
  constructor(componentName) {
    super(componentName)  // Params passed to Input

    this.options = {
      addItemText: false,
      classNames: {
        containerOuter: 'input--select-wrapper',
        containerInner: 'input--select',
        input: 'input--select--fake',
        inputCloned: 'input--select__input--cloned',
        list: 'input--select__list',
        listItems: 'input--select__list--multiple',
        listSingle: 'input--select__list--single',
        listDropdown: 'input--select__list--dropdown',
        item: 'input--select__item',
        itemSelectable: 'input--select__item--selectable',
        itemDisabled: 'input--select__item--disabled',
        itemChoice: 'input--select__item--choice',
        placeholder: 'input--select__placeholder',
        group: 'input--select__group',
        groupHeading: 'input--select__heading',
        button: 'input--select__button'
      },
      itemSelectText: '',
      shouldSort: false,
      searchEnabled: true,
      searchChoices: true,
      searchResultLimit: 300,
      searchFloor: 2,
      searchFields: ['label', 'value'],
      fuseOptions: {
        includeMatches: true,
        findAllMatches: true,
      }
    }

    this.componentName = componentName
  }

  init(component) {
    const selectInput = component.element.getElementsByClassName('input--select--fake')[0]
    const label = component.element.getElementsByClassName('input--label')[0]

    if (selectInput) {
      if (selectInput.dataset.searchEnabled == 'false') {
        component.element.dataset.searchEnabled = false
        this.options.searchEnabled = false
      }

      if (component.element.dataset.height == 'auto' && component.element.dataset.remove === 'true') {
        this.options.removeItemButton = true
      }



      let customLayout = false
			console.log('selectInput.dataset.globalChoices', selectInput.dataset.globalChoices)
      for(let child of selectInput.children) {
        const hasThumbnail = child.dataset.thumbnail
        const hasVimeoId   = child.dataset.vimeoId
        if(hasThumbnail || hasVimeoId) {
          customLayout = true
          break;
        }
      }

      if (customLayout) {
        component.element.classList.add('has-thumbnail')

        const items = []
        const options = selectInput.children

        for(let option of options) {
          const value = option.value
          const label = option.text
          const thumbnail = option.dataset.thumbnail
          const vimeo   = option.dataset.vimeoId
          const placeholder = value == '' ? true : option.dataset.placeholder
          const selected = option.selected
          const disabled = option.disabled
          items.push({ value, label, customProperties: { vimeo, thumbnail, placeholder }, selected, disabled })
        }

        removeAllChild(selectInput)

        this.options.choices = items
        this.options.callbackOnCreateTemplates = function(template) {
          return {
            item: (classNames, data) => {
              return template(`
                <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable } ${data.placeholder ? classNames.placeholder : ''}" data-item data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''} ${data.disabled ? 'aria-disabled="true"' : ''}>
                  <div class='option'>
                    ${data.customProperties.vimeo ?
                      `<i class='option--media'>
                        <img src='/assets/cms/i--video.svg' alt='video preview'/>
                      </i>`
                    : ''}
                    <figure class='option__thumbnail${data.customProperties.thumbnail ? '' : ' empty'}${data.customProperties.vimeo ? ' cmp-vimeo' : ''}' ${data.customProperties.vimeo ? `data-id="${data.customProperties.vimeo}"` : ''} >
                      ${data.customProperties.thumbnail ?
                        `<img src='${data.customProperties.thumbnail}' alt='option thumbnail' />` : ''
                      }
                    </figure>
                    <span class='option__label'>${data.label}</span>
                  </div>
                </div>
              `)
            },
            choice: (classNames, data) => {
              return template(`
                <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}" data-select-text="${this.config.itemSelectText}" data-choice ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'} data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
                  <div class='option'>
                    ${data.customProperties.vimeo ?
                      `<i class='option--media'>
                        <img src='/assets/cms/i--video.svg' alt='video preview'/>
                      </i>`
                    : ''}
                    <figure class='option__thumbnail${data.customProperties.thumbnail ? '' : ' empty'}${data.customProperties.vimeo ? ' cmp-vimeo' : ''}' ${data.customProperties.vimeo ? `data-id="${data.customProperties.vimeo}"` : ''} >
                      ${data.customProperties.thumbnail ?
                        `<img src='${data.customProperties.thumbnail}' alt='option thumbnail'/>` : ''
                      }
                    </figure>
                    <span class='option__label'>${data.label}</span>
                  </div>
                </div>
              `)
            }
          }
        }
      }

      component.selectChoices = new Choices(selectInput, this.options)

      // Reset default searchEnabled options for the next incoming component to be initialized
      this.options.searchEnabled = true
      this.options.removeItemButton = false
      // Reset default choices to empty array
      this.options.choices = []

      if(customLayout) {
        this.options.choices = []
        this.options.callbackOnCreateTemplates = null
      }

      this.addCustomEvents(component, selectInput, label)
    }
  }


  addCustomEvents(component, selectInput, label) {
    const element = component.element

    if (selectInput) {
      selectInput.addEventListener('showDropdown', (e) => {
        element.classList.add('is-focused')
        if (label) label.classList.add('active')
      })

      selectInput.addEventListener('hideDropdown', () => {
        element.classList.remove('is-focused')
        if (label) label.classList.remove('active')
      })

			// if (selectInput.dataset.searchEnabled != 'false') {
			// 	selectInput.addEventListener('search', (e) => {
			// 	})
			// }
      this.checkIfToggle(component, selectInput)
    }

    if (element.getAttribute('data-filter-name') == 'sort') {
      selectInput.addEventListener('choice', (e) => {
        const param = `sort=${e.detail.choice.value}`
        app.components.filter.updateParams('sort', param)
      })
    }
  }

  checkIfToggle(component, select) {
    const toggle = select.dataset.toggle

    if(toggle) {
      select.addEventListener('choice', (e) => {
        setTimeout(() => {
          const choice    = component.selectChoices.getValue()
          const fieldsets = select.form.querySelectorAll(`fieldset[data-toggle="${toggle}"]`)
          const divs      = select.form.querySelectorAll(`div[data-toggle="${toggle}"]`)

          for(let fieldset of fieldsets) {
            fieldset.setAttribute('aria-expanded', fieldset.dataset.toggleValue.split(',').indexOf(choice.value) !== -1)
          }

          for(let div of divs) {
            if(!div.classList.contains('input-radio-wrapper')) {
              div.setAttribute('aria-expanded', div.dataset.toggleValue.split(',').indexOf(choice.value) !== -1)
            }
          }
        }, 200)
      })
    }
  }
}


export default InputSelect
